@import "../node_modules/bootstrap/scss/bootstrap";

.link-button
{
    cursor: pointer;
}

label, :focus, :active {
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
